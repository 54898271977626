import React from 'react';
import { Button, LoadMoreButtonWrapper } from 'mm-ui-components';
import { useDispatch } from 'react-redux';
import { fetchNextPosts } from '../../store/template/loadMore.actions';
import { AppState } from '../../store/initialStateRegistration';
import { AdditionalStyleSheet } from '../../templates/templates.utils';

export type NextByKeySelector<SectionNamesList> = (key: SectionNamesList) => (state: AppState) => string | null;


interface LoadMoreButtonConnectedProps {
  shouldDisplay: boolean;
  text: string | null;
  additionalStyle?: AdditionalStyleSheet;
  onClick: () => void;
}

export const LoadMoreButton: React.FunctionComponent<LoadMoreButtonConnectedProps> = ({ shouldDisplay, text, onClick, additionalStyle }) => {
  return shouldDisplay
    ? (
      <LoadMoreButtonWrapper>
        <Button onClick={onClick} label={text} additionalStyle={additionalStyle} />
      </LoadMoreButtonWrapper>
)
   : null;
};


export function useFetchNextPosts <SectionNamesList>(sectionKey: SectionNamesList, nextSelector: NextByKeySelector<SectionNamesList>) {
  const dispatch = useDispatch();
  return () => dispatch(fetchNextPosts(sectionKey, nextSelector));
}
