import { Dispatch } from 'redux';
import { fetchMoreArticles } from '../../services/pilotServiceApiProvider';
import {
  getEditionEndpoint,
  getImagesCdnHost,
  getLocale,
  getMidnightInTimeZone,
  getTimeZone,
} from '../config/config.selectors';
import { AppState } from '../initialStateRegistration';
import { getSafeObject, mandatory, nonMandatory } from '../storeFromRequestParamsValidation';
import { NextByKeySelector } from '../../components/connectedComponents/LoadMoreButton';
import { Article } from './template.state.types';
import { createArticles } from '../articles.utils';

interface PostsResponse {
  pagination: {
    next: string;
  };
  articles: Array<object>;
}

export const RECEIVED_POSTS_SUCCESSFULLY = 'RECEIVED_POSTS_SUCCESSFULLY' as const;
export const receivePostsSuccessfully = (response: PostsResponse, key: string, imagesHost: string, midnightInTimeZone: string, timeZone: string, locale: string, editionEndpoint: string) => {
  const receivePostsRegistration = (safeResponse: PostsResponse) => ({
    type: RECEIVED_POSTS_SUCCESSFULLY,
    articles: mandatory(safeResponse.articles, [], createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    next: nonMandatory(safeResponse.pagination.next),
    sectionKey: key,
  });
  return receivePostsRegistration(getSafeObject(response, 'moreArticlesResponse'));
};

interface ReceivedPostsSuccessfullyAction<SectionNamesList> {
  type: typeof RECEIVED_POSTS_SUCCESSFULLY;
  sectionKey: SectionNamesList;
  articles: Array<Article>;
  next: string;
}

export const RECEIVED_POSTS_UNSUCCESSFULLY = 'RECEIVED_POSTS_UNSUCCESSFULLY' as const;
export const receivePostsUnsuccessfully = (error: string) => {
  return {
    type: RECEIVED_POSTS_UNSUCCESSFULLY,
    error,
  };
};

export const REQUEST_POSTS = 'Request next posts from service' as const;
export const requestPosts = () => {
  return {
    type: REQUEST_POSTS,
  };
};

interface ReceivedPostsUnsuccessfullyAction {
  type: typeof RECEIVED_POSTS_UNSUCCESSFULLY;
}

interface RequestPostsAction {
  type: typeof REQUEST_POSTS;
}

export type LoadMoreAction<SectionNamesList> = ReceivedPostsSuccessfullyAction<SectionNamesList> | ReceivedPostsUnsuccessfullyAction | RequestPostsAction;

export function fetchNextPosts<T extends AppState, SectionNamesList>(key: SectionNamesList, getSectionNextByKeySelector: NextByKeySelector<SectionNamesList>) {
  return (dispatch: Dispatch, getState: () => T) => {
    dispatch(requestPosts());
    const endpoint = getSectionNextByKeySelector(key)(getState());
    if (endpoint) {
      return fetchMoreArticles(endpoint)
        .then((json: PostsResponse) => dispatch(receivePostsSuccessfully(json, key as unknown as string, getImagesCdnHost(getState()), getMidnightInTimeZone(getState()), getTimeZone(getState()), getLocale(getState()), getEditionEndpoint(getState()))))
        .catch((error: string) => dispatch(receivePostsUnsuccessfully(error)));
    }
    return null;
  };
}
