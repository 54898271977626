import { mandatory, nonMandatory, valid } from './storeFromRequestParamsValidation';
import { Article } from './template/template.state.types';
import { createLinkSvg } from './navigations/navigation.utils';
import { createMainCategory } from './template/postPage/utils/createMainCategory.utils';
import { AuthorWithSocial } from './template/postPage/postPage.reducer';

export const createPathWithExtension = (path: string | undefined, extension: string | undefined) => {
  if (!path || !extension) {
    return null;
  }
  return `${path}.${extension}`;
};

const createImageCroppingData = (croppingData: any) => ({
  x: mandatory(croppingData.x, 0),
  y: mandatory(croppingData.y, 0),
  width: mandatory(croppingData.width, 0),
  height: mandatory(croppingData.height, 0),
});

export const createImage = (host: string, aspectRatio?: object) => (image: any) => {
  const path = mandatory(image.path, '');
  const fileExtension = mandatory(image.fileExtension, '');
  const alternateHost = nonMandatory(image.host);

  return {
    host: mandatory(alternateHost || host, ''),
    path: createPathWithExtension(path, fileExtension),
    credit: nonMandatory(image.credit),
    caption: nonMandatory(image.caption),
    alt: nonMandatory(image.alt),
    link: nonMandatory(image.linkURL, (linkURL: string) => ({
      linkURL,
      targetAttributeValue: nonMandatory(image.linkTargetAttribute),
      relAttributeValue: nonMandatory(image.linkRelAttribute),
    })),
    cropping: nonMandatory(image.cropping, createImageCroppingData),
    ...aspectRatio ? { aspectRatio } : {},
    rawHtml: nonMandatory(image.fullImageHtml),
  };
};

export const createCoverVideo = (media: any) => {
  const rawHtmlCover = nonMandatory(media.value.rawHTML);
  const type = nonMandatory(media.type);
  if (rawHtmlCover) {
    return {
      rawHtml: rawHtmlCover,
      type: 'rawHtml',
    };
  }
  if (type === 'youtube') {
    return {
      html: nonMandatory(media.value.html),
      mediaId: nonMandatory(media.value.mediaId),
      type,
    };
  }
  if (type === 'mmPlayer') {
    return {
      html: mandatory(media.value.html, ''),
      mediaId: mandatory(media.value.mediaId, ''),
      playerId: mandatory(media.value.playerId, ''),
      version: nonMandatory(media.value.version) || '',
      type,
    };
  }
  if (type === 'jw') {
    return {
      html: mandatory(media.value.html, ''),
      videoId: mandatory(media.value.videoId, ''),
      playerId: mandatory(media.value.playerId, ''),
      type,
    };
  }
  return null;
};

export const createAuthorPagePath = (editionEndpoint: string) => (path: string) => {
  return `https://${editionEndpoint}${path}`;
};

export const createAuthor = (imagesCDNHost: string, editionEndpoint: string) => (author: any) => {
  const defaultName = mandatory(author.name, '');
  const defaultUserName = mandatory(author.username, '');
  return {
    name: nonMandatory(author.authorInfo.name.value) || defaultName,
    byline: nonMandatory(author.authorInfo.byline.value),
    link: nonMandatory(author.pagePath, createAuthorPagePath(editionEndpoint)),
    image: nonMandatory(author.authorInfo.image.value, createImage(imagesCDNHost)),
    username: nonMandatory(author.userName) || defaultUserName,
  };
};

export const createAuthors = (hideOwner: boolean, imagesCDNHost: string, editionEndpoint: string) => (authors: any) => {
  const additionalAuthors = nonMandatory(authors.additional, (additional: any) => additional.map(createAuthor(imagesCDNHost, editionEndpoint)));
  const owner = mandatory(authors.owner, null, createAuthor(imagesCDNHost, editionEndpoint));
  const allAuthors = [
    ...((hideOwner || !owner) ? [] : [owner]),
    ...(additionalAuthors || []),
  ];
  return allAuthors;
};

const createAuthorWithSocialLinks = (
  imagesCDNHost: string,
  author: any,
  editionEndpoint: string,
): AuthorWithSocial => {
  const createSvgLinks = (socialImageLinks: {
    alt: string;
    src: string;
    url: string;
  }[]) => socialImageLinks.map(createLinkSvg);

  return {
    name: mandatory(author.authorInfo.name.value, author.name || ''),
    byline: nonMandatory(author.authorInfo.byline.value),
    image: nonMandatory(author.authorInfo.image.value, createImage(imagesCDNHost)),
    socialLinks: nonMandatory(author.authorInfo.socialLinks.value, createSvgLinks),
    bio: nonMandatory(author.authorInfo.bio.value),
    link: nonMandatory(author.pagePath, createAuthorPagePath(editionEndpoint)),
    username: mandatory(author.userName, author.username || ''),
  };
};

export const createAuthorsWithSocialLinks = (imagesCDNHost: string, editionEndpoint: string, hideOwner: boolean) => (authors: any) => {
  const additionalAuthors = nonMandatory(authors.additional, (additional: any) => additional.map((author: any) => {
    if (!author) {
      return null;
    }
    return createAuthorWithSocialLinks(imagesCDNHost, author, editionEndpoint);
  }));
  const owner = mandatory(authors.owner, null, () => createAuthorWithSocialLinks(imagesCDNHost, authors.owner, editionEndpoint));

  return [
    ...((hideOwner || !owner) ? [] : [owner]),
    ...(additionalAuthors || []),
  ];
};

const removeSeconds = (time: string) => time.replace(/:\d+\s/gi, ' ');

const DATE_MED = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
export const createDate = (locale: string, timeZone: string, articleDateISO: string) => {
  const articleDate = Date.parse(articleDateISO);
  return Intl.DateTimeFormat(locale, { timeZone, ...DATE_MED }).format(articleDate);
};

const TIME_WITH_SHORT_OFFSET = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
};
export const createTime = (locale: string, timeZone: string, articleDateISO: string) => {
  const articleDate = Date.parse(articleDateISO);
  return removeSeconds(Intl.DateTimeFormat(locale, { timeZone, ...TIME_WITH_SHORT_OFFSET }).format(articleDate));
};

export const createTimeAndDate = (locale: string, timeZone: string, articleDateISO: string) => {
  const articleDate = Date.parse(articleDateISO);
  return removeSeconds(Intl.DateTimeFormat(locale, { timeZone, ...TIME_WITH_SHORT_OFFSET, ...DATE_MED }).format(articleDate));
};

export const createShortDateOrTime = (midnightInTimeZoneISO: string, timeZone: string, locale: string) => (articleDateISO: string) => {
  const articleDate = Date.parse(articleDateISO);
  const midnightInTimeZone = Date.parse(midnightInTimeZoneISO);
  const time = createTime(locale, timeZone, articleDateISO);
  const date = createDate(locale, timeZone, articleDateISO);
  return articleDate >= midnightInTimeZone ? time : date;
};

const isValidTimeZone = (timeZone: string) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (e) {
    return false;
  }
};

export const createTimeZoneData = (data: any) => {
  const timeZoneData = mandatory(data.propertySettings.IANATimezoneName, 'America/New_York');
  return valid('timeZone', timeZoneData, 'America/New_York', isValidTimeZone);
};

const isValidLocale = (locale: string) => {
  const localeRegex = /^[a-z]{2}-[A-Z]{2}$/;
  return localeRegex.test(locale);
};

export const createLocaleData = (data: any) => {
  const localeData = mandatory(data.propertySettings.locale, 'en-US');
  return valid('locale', localeData, 'en-US', isValidLocale);
};

export const createArticles = (imagesHost: any, midnightInTimeZoneISO: string, timeZone: string, locale: string, editionEndpoint: string) => (articles: Array<any>) => {
  return articles.map((article: any): Article => {
    const hideOwner = nonMandatory(article.metadata.hideOwner);
    return {
      id: mandatory(article.id, ''),
      title: mandatory(article.title, ''),
      image: mandatory(article.cover.image.value, {}, createImage(imagesHost)),
      coverType: mandatory(article.coverType, ''),
      articleUrl: mandatory(article.articleUrl, ''),
      createdAt: mandatory(article.createdAt, '', createShortDateOrTime(midnightInTimeZoneISO, timeZone, locale)),
      createdAtISO: mandatory(article.createdAt, ''),
      updatedAt: mandatory(article.updatedAt, '', createShortDateOrTime(midnightInTimeZoneISO, timeZone, locale)),
      updatedAtISO: mandatory(article.updatedAt, ''),
      featuredAt: mandatory(article.featuredAt, ''),
      authors: mandatory(article.authors, [], createAuthors(hideOwner, imagesHost, editionEndpoint)),
      description: mandatory(article.seoDescription, ''),
      mainCategory: nonMandatory(article.mainCategory, createMainCategory(editionEndpoint)),
      sponsoredText: nonMandatory(article.metadata.sponsor.text),
      brandName: nonMandatory(article.metadata.sponsor.brandName),
      logo: nonMandatory(article.metadata.sponsor.image),
    };
  });
};
