import { Svg, Link } from '../../navigation.utils';

export interface NetworkLinksState {
    logo: Svg;
    links: Link[];
    title: string;
}

export const networkLinksReducer = (state: NetworkLinksState = {} as NetworkLinksState, action: {type: string}) => {
    switch (action.type) {
        default:
            return state;
    }
};
