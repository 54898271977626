import { AppState, HomePagePhoenixAppState } from '../../../initialStateRegistration';
import {
    HomePagePhoenixFeedSection,
    HomePagePhoenixMinorSections,
    HomePagePhoenixArticlesOnlySection,
} from './homePagePhoenix.initialState';

export const getHomePage = (state: AppState) => (state as HomePagePhoenixAppState).template;

export const getSectionDataByKey = (key: HomePagePhoenixArticlesOnlySection | HomePagePhoenixMinorSections | HomePagePhoenixFeedSection) => (state: AppState) => getHomePage(state)[key];

export const getSectionArticlesByKey = (key: HomePagePhoenixArticlesOnlySection | HomePagePhoenixMinorSections) => (state: AppState) => getHomePage(state)[key].articles;

export const getFeedSectionArticlesByKey = (key: HomePagePhoenixFeedSection) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: HomePagePhoenixMinorSections | HomePagePhoenixFeedSection) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getMoreButtonTextByKey = (key: HomePagePhoenixMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMoreButtonSvgByKey = (key: HomePagePhoenixMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonSvg;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getShowMorePaginationTextByKey = (key: HomePagePhoenixFeedSection) => (state: AppState) => getHomePage(state)[key].showMorePaginationText;

export const getShowMorePaginationURLByKey = (key: HomePagePhoenixFeedSection) => (state: AppState) => getHomePage(state)[key].showMorePaginationURL;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;

export const getTopic = (state: AppState) => getHomePage(state).topic;

export const getVertical = (state: AppState) => getHomePage(state).customVertical;

export const getScoreTickerSport = (state: AppState) => getHomePage(state).scoreTickerSport;
