import { HomePageParisState } from './homePageParis/homePageParis.initialState';
import { HomePageDubaiState } from './homePageDubai/homePageDubai.initialState';
import { HomePageIstanbulState } from './homePageIstanbul/homePageIstanbul.initialState';
import { HomePageKigaliState } from './homePageKigali/homePageKigali.initialState';
import { HomePageNewYorkState } from './homePageNewYork/homePageNewYork.initialState';
import { HomePageSingaporeState } from './homePageSingapore/homePageSingapore.initalState';
import { HomePageTelAvivState } from './homePageTelAviv/homePageTelAviv.initialState';
import { HomePageTokyoState } from './homePageTokyo/homePageTokyo.initialState';
import { HomePageSeoulState } from './homePageSeoul/homePageSeoul.initialState';
import { HomePagePhoenixState } from './homePagePhoenix/homePagePhoenix.initialState';
import { HomePageMiamiState } from './homePageMiami/homePageMiami.initialState';
import { HomePageFlorenceState } from './homePageFlorence/homePageFlorence.initialState';
import { HomePageBogotaState } from './homePageBogota/homePageBogota.initialState';
import { HomePageBuenosAiresState } from './homePageBuenosAires/homePageBuenosAires.initialState';
import {
  LoadMoreAction,
  RECEIVED_POSTS_SUCCESSFULLY,
  RECEIVED_POSTS_UNSUCCESSFULLY,
  REQUEST_POSTS,
} from '../loadMore.actions';
import { SECTION_NAMES } from '../../../templates/templates.const';
import { HomePageExpandableSection } from '../template.state.types';
import { HomePageSantiagoState } from './homePageSantiago/homePageSantiago.initialState';

type HomePageState =
  HomePageParisState
  | HomePageDubaiState
  | HomePageIstanbulState
  | HomePageKigaliState
  | HomePageNewYorkState
  | HomePageSingaporeState
  | HomePageTelAvivState
  | HomePageTokyoState
  | HomePageSeoulState
  | HomePagePhoenixState
  | HomePageSantiagoState
  | HomePageFlorenceState
  | HomePageBogotaState
  | HomePageMiamiState
  | HomePageBuenosAiresState;

interface HomePageWithMinorSection {
  [SECTION_NAMES.EXPANDABLE_SECTION]: HomePageExpandableSection;
}
export type HomePageAction = { type: string };

export const homePageReducer = <T extends HomePageWithMinorSection>(state: T = {} as T, action: LoadMoreAction<keyof HomePageWithMinorSection>) => {
  switch (action.type) {
    case RECEIVED_POSTS_SUCCESSFULLY: {
      const oldArticles = state[action.sectionKey].articles || [];
      return {
        ...state,
        [action.sectionKey]: {
          ...state[action.sectionKey],
          articles: [
            ...oldArticles,
            ...action.articles,
          ],
          showMorePaginationURL: action.next,
        },
      };
    }
    case RECEIVED_POSTS_UNSUCCESSFULLY: {
      return state;
    }

    case REQUEST_POSTS: {
      return state;
    }
    default:
      return state;
  }
};
