import axios from 'axios';

export const preformFetch = (endpoint: string) => {
  return axios(endpoint)
    .then(response => {
        if (response.status === 200) {
            return response.data;
      }
        const error = {
            response: `response status is ${response.status}`,
      };
        return Promise.reject(error);
    })
    .catch(error => {
        return Promise.reject(error.response);
    });
};
