import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  MEDIA_BREAKPOINTS,
  OneHC,
  ScrollableArticleList,
  SecondarySectionTitle,
  Section,
  SectionHeader,
  SectionWithTitle,
  OneOTCThreeHLC,
  HalfScreenWrapper,
  OneOTCThreeHCVertical,
  ThreeVCThreeHCThreeVCThreeHC,
  SportradarFactory,
  SPORTRADAR_NAMES,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { Page } from '../Page';
import { getAdSlotId, getAdSlotOnSuccess } from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import {
  getFeedSectionArticlesByKey,
  getMetadataDescription,
  getMetadataTitle,
  getMoreButtonSvgByKey, getMoreButtonTextByKey,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getShowMorePaginationTextByKey,
  getShowMorePaginationURLByKey,
  getMetadataImage,
  getHreflangData,
  getScoreTickerSport,
} from '../../store/template/homePage/homePagePhoenix/homePagePhoenix.selectors';
import { LoadMoreButton, useFetchNextPosts } from '../../components/connectedComponents/LoadMoreButton';
import { NetworkLinksConnected } from '../../components/connectedComponents/NetworkLinksConnected';

interface HomePagePhoenixProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const cardsSectionArticles = getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION)(state) || [];
  const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1)(state) || [];
  const contentSection2Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2)(state) || [];
  const contentSection3Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3)(state) || [];
  const contentSection4Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4)(state) || [];
  const feedSectionArticles = getFeedSectionArticlesByKey(SECTION_NAMES.EXPANDABLE_SECTION)(state) || [];

  return [
    ...topSectionArticles,
    ...cardsSectionArticles,
    ...contentSection1Articles,
    ...contentSection2Articles,
    ...contentSection3Articles,
    ...contentSection4Articles,
    ...feedSectionArticles,
  ];
};

export const HomePagePhoenixComponent: React.FunctionComponent<HomePagePhoenixProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePagePhoenixContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * 3}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit * 0.5}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
  topSectionsWrapper: {
    gridRowGap: `${baseUnit * 0.5}px`,
    gridTemplateRows: 'repeat(4, auto)',
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit * 0.25}px`,
    justifyItems: 'center',
  },
  widget: {
    margin: '0',
  },
  widgetWrapper: {
    marginBottom: '5px',
    marginTop: '-4px',
    padding: '0',
    minHeight: '60px',
  },
});


export const HomePagePhoenixContentConnected: React.FunctionComponent = () => {
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
  const cardsSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CARDS_SECTION));
  const contentSection1DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1nMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection2DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection4DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const feedSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.EXPANDABLE_SECTION));
  const feedSectionArticles = useSelector(getFeedSectionArticlesByKey(SECTION_NAMES.EXPANDABLE_SECTION));
  const articles = useSelector(getArticlesInTemplate);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const stickyBottomId = useSelector(getAdSlotId(SLOT_NAMES.STICKY_BOTTOM));
  const belowTopSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSecondSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_SECOND_SECTION));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const stickyBottomOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.STICKY_BOTTOM));
  const belowTopSectionOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSecondSectionOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_SECOND_SECTION));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const pageImage = useSelector(getMetadataImage);
  const hreflangData = useSelector(getHreflangData) || [];
  const defaultSport = useSelector(getScoreTickerSport)?.toLowerCase();
  const style = createStyle();

  return (
    <React.Fragment>
      <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent" isFullBleed>
            {defaultSport && (
              <SportradarFactory
                name={SPORTRADAR_NAMES.TICKER}
                wrapperStyle={style.widgetWrapper}
                widgetStyle={style.widget}
                sportradarProps={{ sport: defaultSport }}
              />
            )}
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.topSectionsWrapper)}>
              <Section>
                <OneOTCThreeHLC cards={topSectionArticles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
              </Section>

              <Ad id={belowTopSectionAdId} onSuccess={belowTopSectionOnSuccess} setMinHeight />

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={cardsSectionMoreButtonSvg}
                  moreButtonText={cardsSectionMoreButtonText}
                  paddingFactors={{ large: 0.5, medium: 1, small: 1 }}
                >
                  <SecondarySectionTitle>
                    {cardsSectionDisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ScrollableArticleList cardsProps={cardsSectionArticles} paddingFactors={{ large: 0.5, medium: 1, small: 1 }} />
              </SectionWithTitle>

              <Ad id={belowSecondSectionAdId} onSuccess={belowSecondSectionOnSuccess} />
            </div>
            <div className={css(style.sectionsWrapper)}>
              <HalfScreenWrapper paddingFactors={{ large: 0.5, medium: 1, small: 1 }}>
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={contentSection1nMoreButtonSvg}
                    moreButtonText={contentSection1MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection1DisplayName}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <OneOTCThreeHCVertical cards={contentSection1Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </SectionWithTitle>

                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={contentSection2MoreButtonSvg}
                    moreButtonText={contentSection2MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection2DisplayName}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <OneOTCThreeHCVertical cards={contentSection2Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </SectionWithTitle>
              </HalfScreenWrapper>

              <Section>
                <OneHC cards={contentSection3Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
              </Section>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection4MoreButtonSvg}
                  moreButtonText={contentSection4MoreButtonText}
                  paddingFactors={{ large: 0.5, medium: 1, small: 1 }}
                >
                  <SecondarySectionTitle>
                    {contentSection4DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ScrollableArticleList cardsProps={contentSection4Articles} paddingFactors={{ large: 0.5, medium: 1, small: 1 }} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader moreButtonSvg={null} moreButtonText={null} paddingFactors={{ large: 0.5, medium: 1, small: 1 }}>
                  <SecondarySectionTitle>{feedSectionDisplayName}</SecondarySectionTitle>
                </SectionHeader>
                <ThreeVCThreeHCThreeVCThreeHC cards={feedSectionArticles} paddingFactors={{ large: 0.5, medium: 1, small: 1 }} />
              </SectionWithTitle>
            </div>
            <LoadMoreFeedPostsButtonConnected />

            <div className={css(style.sectionsWrapper)}>
              <Section>
                <NetworkLinksConnected />
              </Section>
            </div>

            <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
          </NavigationLayout>
          <Ad id={stickyBottomId} onSuccess={stickyBottomOnSuccess} isSticky />
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};

export const LoadMoreFeedPostsButtonConnected: React.FunctionComponent = () => {
  const sectionKey = SECTION_NAMES.EXPANDABLE_SECTION;
  const shouldDisplay = !!useSelector(getShowMorePaginationURLByKey(sectionKey));
  const text = useSelector(getShowMorePaginationTextByKey(sectionKey));
  const onClick = useFetchNextPosts(sectionKey, getShowMorePaginationURLByKey);
  return <LoadMoreButton {...{ onClick, shouldDisplay, text }} />;
};
