export class DataTransformationError extends Error {
    constructor(message: string) {
        super();
        this.name = 'DataTransformationError';
        this.message = message;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DataTransformationError);
        }
    }
}
