import { AppState } from '../../store/initialStateRegistration';
import {
  getAdSlots,
  getAmpDfpProperty,
  getEdition,
  getExperiments,
  getLanguage,
  getPageType,
  getSiteName,
} from '../../store/config/config.selectors';
import { getCountryCode } from '../templates.utils';

export const getAnalyticsCoreParamsForAllPages = (state: AppState) => ({
  siteName: getSiteName(state),
  property: getEdition(state),
  language: getLanguage(state),
  pageType: getPageType(state),
  adSlots: getAdSlots(state),
  countryCode: getCountryCode(),
  dfpProperty: getAmpDfpProperty(state),
  experiments: getExperiments(state),
});
