import { preformFetch } from './basicApiProvider';

interface Response {
    data: {
        pagination: {
            next: string;
        };
        articles: Array<object>;
    };
}

const getResponseData = (response: Response) => response.data;

export const fetchMoreArticles = (endpoint: string) => {
  return preformFetch(endpoint)
    .then(response => {
      return getResponseData(response);
    });
};
